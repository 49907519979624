export default {
	quotationList: [
		{
			name: '《悟空传》',
			text: '我要这天，再遮不住我眼，要这地，再埋不了我心，要这众生，都明白我意，要那诸佛，都烟消云散！'
		},
		{
			name: '《盗墓笔记》',
			text: '比鬼神更可怕的，是人心。'
		},
		{
			name: '《春日忆李白》',
			text: '白也诗无敌，飘然思不群。'
		},
		{
			name: '《侠客行》',
			text: '十步杀一人，千里不留行。事了拂衣去，深藏身与名。'
		},
		{
			name: '《南陵别儿童入京》',
			text: '仰天大笑出门去，我辈岂是蓬蒿人。'
		},
		{
			name: '《江上吟》',
			text: '兴酣落笔摇五岳，诗成笑傲凌沧洲。'
		},
		{
			name: '《经乱离后天恩流夜郎忆旧游书怀赠江夏韦太守良宰》',
			text: '仙人抚我顶，结发受长生。'
		},
		{
			name: '《江上值水如海势聊短述》',
			text: '为人性僻耽佳句，语不惊人死不休。'
		},
		{
			name: '《青玉案·元夕》',
			text: '众里寻他千百度。蓦然回首，那人却在，灯火阑珊处。'
		},
		{
			name: '《晓出净慈寺送林子方》',
			text: '接天莲叶无穷碧，映日荷花别样红。'
		},
		{
			name: '《题都城南庄》',
			text: '去年今日此门中，人面桃花相映红。人面不知何处去，桃花依旧笑春风。'
		},
		{
			name: '《望月怀远》',
			text: '海上生明月，天涯共此时。'
		},
		{
			name: '《宣州谢朓楼饯别校书叔云》',
			text: '抽刀断水水更流，举杯消愁愁更愁。'
		},
		{
			name: '《离思五首·其四》',
			text: '曾经沧海难为水，除却巫山不是云。'
		},
		{
			name: '《蝶恋花·伫倚危楼风细细》',
			text: '衣带渐宽终不悔，为伊消得人憔悴。'
		},
		{
			name: '《鹊桥仙》',
			text: '两情若是久长时，又岂在、朝朝暮暮。'
		},
		{
			name: '《登高》',
			text: '无边落木萧萧下，不尽长江滚滚来。'
		},
		{
			name: '《木兰词·拟古决绝词柬友》',
			text: '人生若只如初见，何事秋风悲画扇。'
		},
		{
			name: '《摸鱼儿·雁丘词 / 迈陂塘》',
			text: '问世间，情为何物，直教生死相许？'
		}
	]
}