import React from 'react';
import './FooterInfoComponent.scss';

class FooterInfoComponent extends React.Component {
	render() {
		const beian = require('../../assets/img/beian.png');

		return <div className="footer-info-container flex-between-center">
			<p></p>
			<div className="flex-between-center">
				{/*<p className="foot-split-block">Copyright © 2019-2019 qian033.com. All Rights Reserved.</p>*/}
				<div className="beian-block">
					<a
						target="_blank"
						href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602011392"
						rel="noopener noreferrer"
					>
						<img src={beian} />
						<p>粤公网安备 44010602011392号</p>
					</a>
				</div>
				<p className="foot-split-block">
					<a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">粤ICP备2023011970号</a>
				</p>
			</div>
		</div>
	}
}

export default FooterInfoComponent;
